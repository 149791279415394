//let callback = 'https://deploy.charitable.one/callback';
//let callback = 'https://abs.charitable.one/callback';


let callback = "https://identity.charitable.one/callback";
let usersAPI = 'https://3g2lc2qtt2.execute-api.us-east-1.amazonaws.com/production/user/';

if (window.location.hostname === "localhost") {

  callback = 'http://localhost:3000/callback';
  usersAPI = "https://mghumdzs81.execute-api.us-east-1.amazonaws.com/staging/user/";

} else if (window.location.hostname === "0f40a744cdec4e6ea4a6275ba76ec890.vfs.cloud9.us-east-1.amazonaws.com") {

  callback = 'https://0f40a744cdec4e6ea4a6275ba76ec890.vfs.cloud9.us-east-1.amazonaws.com/callback';

} else if (window.location.hostname === "dev-identity.charitable.one") {

  callback = 'https://dev-identity.charitable.one/callback';
  usersAPI = "https://mghumdzs81.execute-api.us-east-1.amazonaws.com/staging/user/";


} else if (window.location.hostname === "identity.charitable.one/callback") {

  callback = 'https://identity.charitable.one/callback';


} else if (window.location.hostname === "192.168.2.103") {

  callback = 'http://192.168.2.103:3000/callback';


}

if (window.location.hostname === "identity.charitable.one") {

  usersAPI = 'https://3g2lc2qtt2.execute-api.us-east-1.amazonaws.com/production/user/';

}




let options = {
  theme: {
    logo: 'https://public.charitable.one/assets/branding/one-logo.png',
    primaryColor: '#f44336'
  }
};

export const AUTH_CONFIG = {
  domain: 'rkd.auth0.com',
  clientId: 'bjGm9MIxwf1uJttaEeKuXpLE535tGRsF',
  callbackUrl: callback,
  options: options,
  usersAPI: usersAPI
};
