// packages
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";

// Import Columns
import {
  general_email_submitted as emailSignUps,
} from "../ClientData/actions";

//import components
import { ChangeInitial } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DynamicCards from "../Shared/DynamicCards";

const EmailSignUps = () => {

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client } = useSelector(({ main }) => main);

  useEffect(() => {
    dispatch(ChangeInitial("Email Sign-ups"));
  }, [dispatch]);

  return (
    <div className="container-fluid">
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <div className="dashboard dynamic-card">

          {/* Email Sign-ups Card */}
          <DynamicCards
            title="Email Sign-ups"
            formType="general_email_submitted"
            headerIcon="drafts"
            background="linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))"
            // background="linear-gradient(60deg, rgb(0, 98, 114), rgb(0, 59, 73))"
            columns={emailSignUps}
            card={["Person", "Device"]}
          />

        </div>
      )}
    </div>
  );
};

export default EmailSignUps;