/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import * as ss from 'simple-statistics'
import { SkeletonLine } from "../../Views/UI/skeletonLoader";

dayjs.extend(weekday);
dayjs.extend(localeData);


//Plugins
import moment from 'moment';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

const { RangePicker } = DatePicker;

const AvgCharts = () => {

    const { client, IdentityApi } = useSelector(({ main }) => main);
    const [activeTab, setActiveTab] = useState("oneTime");
    const [average, setaverage] = useState([]);
    const [data, setData] = useState([]);
    const [dates, setDates] = useState({
        startDate: dayjs().subtract(60, 'days'),
        endDate: dayjs().subtract(1, 'days')
    })
    const [isLoading, setisLoading] = useState(true);
    const [ApiData, setApiData] = useState([]);
    const [disableStartDate, setDisableStartDate] = useState('');

    useEffect(() => {
        fetchData();
    }, [dates, client]);

    useEffect(() => {
        setDates({
            startDate: dayjs().subtract(60, 'days'),
            endDate: dayjs().subtract(1, 'days')
        })
        get_donations_start_date();
    }, [client]);

    const get_donations_start_date = async () => {
        try {
            const formData = {
                client: client.toLowerCase(),
                donation_type: 'onetime'
            };

            const results = await axios.post(`${IdentityApi}query/get_donations_start_date`, formData);

            if (results.data.status === 'success') {
                const date = results.data.data.find((res) => res.unixdate);
                setDisableStartDate(date.unixdate);
                let daysDifference = dayjs(dates.startDate).diff(date.unixdate, 'days');

                if (daysDifference < 0) {
                    setDates({
                        startDate: dayjs(date.unixdate),
                        endDate: dayjs().subtract(1, 'days'),
                    });
                }
            }
        } catch (error) {
            let Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, qty: 0, y: 0 });
            }
            setData(Array)
            setApiData({})
            setisLoading(false)
            console.error("An error occurred:", error);
        }
    };


    const fetchData = async (tab) => {
        try {
            setisLoading(true);

            // Parse start and end dates
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            const donationType = tab ? tab : activeTab;

            const formData = {
                start_date: dayjs(dates.startDate).format('YYYY/MM/DD'),
                end_date: dayjs(dates.endDate).format('YYYY/MM/DD'),
                client: client.toLowerCase(),
                donation_type: donationType.toLowerCase()
            };

            const results = await axios.post(`${IdentityApi}query/get_donations`, formData);

            if (results.data.status === 'success') {
                const countsData = results.data.data;
                setApiData(results.data.data)
                const Array = [];

                for (let i = 0; i <= dateDifference; i++) {
                    const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                    const matchingData = countsData[currentDate] || [];
                    const transactionAmount = matchingData.reduce((total, item) => total + (parseFloat(item.transaction_amount) || 0), 0);
                    const total = matchingData.length;
                    const average = total ? transactionAmount / total : 0;

                    Array.push({ date: currentDate, average: parseFloat(average.toFixed(2)), qty: total });
                }

                //reference line 
                const dates = [];
                const values = [];

                Array.map((entry) => {
                    const date = entry.date;
                    const value = entry.average;
                    dates.push(date);
                    values.push(value);
                });

                const regression = ss.linearRegression(
                    values.map((value, index) => [index, value])
                );
                const predict = ss.linearRegressionLine(regression);
                const predictedValues = dates.map((date, index) => ({
                    date: date,
                    y: parseFloat(predict(index).toFixed(2)),
                }));
                Array && Array.forEach(item => {
                    const matchingItem = predictedValues.find(d => d.date === item.date);
                    if (matchingItem) {
                        item.y = matchingItem.y;
                    }
                });
                setData(Array);
                setaverage(Array);
                setisLoading(false)
            }
        } catch (error) {
            let Array = [];
            const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');

            const dateDifference = moment(endDate).diff(moment(startDate), 'days');
            //calculate dashbard Rechart  
            for (let i = 0; i <= dateDifference; i++) {
                // Get the current date
                const currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
                Array.push({ date: currentDate, average: 0, qty: 0, y: 0 });
            }
            setData(Array)
            setApiData({})
            setisLoading(false)
            console.error("An error occurred:", error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        fetchData(tab)
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData['average']);
        }, 5);

        return () => clearTimeout(timer);
    }, [activeTab, average]);

    const tabData = {
        average: average
    };

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="date">{`${payload[0].payload.date}`}</p>
                    {activeTab === 'oneTime' && (
                        <>
                            <div>
                                <p className="average">{`Average: $${payload[0].payload.average}`}</p>
                            </div>
                            <div>
                                <p className="average">{`Quantity: ${payload[0].payload.qty}`}</p>
                            </div>
                            <div>
                                <p className="y">{`Trend Line: $${payload[1].payload.y}`}</p>
                            </div>
                        </>
                    )}
                    {activeTab === 'recurring' && (
                        <>
                            <div>
                                <p className="average">{`Average: $${payload[0].payload.average}`}</p>
                            </div>
                            <div>
                                <p className="average">{`Quantity: ${payload[0].payload.qty}`}</p>
                            </div>
                            <div>
                                <p className="y">{`Trend Line: $${payload[1].payload.y}`}</p>
                            </div>
                        </>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="row avg-chart">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color='red'>
                        <i className="material-icons">timeline</i>
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-md-offset-1">
                                <h4 className="card-title">
                                    <RangePicker
                                        value={[dates.startDate, dates.endDate]}
                                        onChange={(date, dateString) => setDates({ startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) })}
                                        allowClear={false}
                                        size={"middle"}
                                        bordered={"false"}
                                        format={"YYYY-MM-DD"}
                                        disabledDate={(current) => current && current < dayjs(disableStartDate)}
                                        separator={<i className="material-icons">arrow_right_alt</i>}
                                    />
                                </h4>
                            </div>
                            <div className="tab-container col-sm-12 col-md-3">
                                <div className="btn-group btn-group-sm">
                                    <button className={`btn tabs ${activeTab === "oneTime" && 'tabshover'}`} onClick={() => handleTabChange("oneTime")}>
                                        One Time
                                    </button>
                                    <button className={`btn tabs ${activeTab === "recurring" && 'tabshover'}`} onClick={() => handleTabChange("recurring")}>
                                        Recurring
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!isLoading ?
                            <div className="row">
                                <div className={`col-md-9 line-chart-container`}>
                                    <div className='col-md-12'>
                                        <div className='charts'>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <LineChart data={data}>
                                                    <XAxis
                                                        allowDataOverflow
                                                        dataKey="date"
                                                        tick={<CustomizedAxisTick />}
                                                        domain={['dataMin', 'dataMax']}
                                                        interval={data ? data.length < 31 ? 0 : Math.round(data.length / 30) : 0}
                                                        height={70}
                                                        padding={{
                                                            right: 6,
                                                            left: 4,
                                                        }}
                                                    />

                                                    <YAxis
                                                        tickFormatter={(value) => `$${parseInt(value)}`}
                                                        allowDataOverflow={true}
                                                        interval="preserveStartEnd"
                                                        width={100}
                                                        padding={{
                                                            top: 10,
                                                            bottom: 4
                                                        }}
                                                    />
                                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                                                    {Object.keys(ApiData).length ?
                                                        <Tooltip content={<CustomTooltip />} />
                                                        : ''
                                                    }
                                                    <Line strokeWidth={2.5} dot={true} type="monotone" dataKey='average' stroke={Object.keys(ApiData).length ? "#4c9ef5" : "white"} />
                                                    <Line strokeWidth={2.5} dot={false} type="monotone" strokeDasharray="5 5" dataKey="y" stroke={Object.keys(ApiData).length ? "red" : "white"} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                        {Object.keys(ApiData).length === 0 && (
                                            <div className='rechart-text'>Data collection in progress</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3 tab-container">

                                    <div className="dashbaord-chart-containt">
                                        {activeTab === "oneTime" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="average-icon1" />
                                                    <div className="ml-5"><span className="icons-containt">Average Donation</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="average-icon2" />
                                                    <div className="ml-5"><span className="icons-containt">Trend Line </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="average-icon1" />
                                                    <div className="ml-5"><span className="icons-containt">Average Donation </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="average-icon2" />
                                                    <div className="ml-5"><span className="icons-containt">Trend Line </span></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>)
}

export default AvgCharts;
