import DistributionTable from "../Views/DistributionTable/DistributionTable";
import Home from "../Views/Home/Home";
import GiveEvents from "../Views/Events/GiveEvents";
import EmailSignUps from "../Views/Events/EmailSignUps";
import EngagedSession from "../Views/Events/EngagedSession";
import AbandonedGiftsLightbox from "../Views/AbandonedGiftsLightbox/AbandonedGiftsLightbox";

export const routesList = [
    {
        path: '/admin/identity-dashboard',
        componentName: () => <Home />
    },
    {
        path: '/admin/distribution-table',
        componentName: () => <DistributionTable />
    },
    {
        path: '/admin/give-events',
        componentName: () => <GiveEvents />
    },
    {
        path: '/admin/email-sign-up-events',
        componentName: () => <EmailSignUps />
    },
    {
        path: '/admin/engaged-sessions',
        componentName: () => <EngagedSession />
    },
    {
        path: '/admin/abandoned-gifts-lightbox',
        componentName: () => <AbandonedGiftsLightbox />
    }
]