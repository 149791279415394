import moment from 'moment';

// Formatting Rule name, can change the title, but don't change the value
export const formattingRuleList = [
    {
        title: "No Formatting Rule",
        value: "",
    },
    {
        title: "Title Case",
        value: "titleCase",
    },
    {
        title: "Lower Case",
        value: "lowerCase",
    },
    {
        title: "Upper Case",
        value: "upperCase",
    },
    {
        title: "US Phone Format",
        value: "usPhoneFormat",
    }
];

// Function will return the rule title based on the value
export const getFormattingRuleNameByValue = (value) => {
    if (value !== undefined && value !== "") {
        if (typeof value === "object" && value.length > 0) {
            value = value[0].rule;
        }
        let ruleName = formattingRuleList.filter(item => item.value === value);
        return ruleName.length > 0 ? ruleName[0].title : "";
    } else {
        return value;
    }
}
// Function will return the rule title based on the value
export const handleFormattingValue = (formattingTypeName) => {
    let tmpFormattingRule = [];
    if (formattingTypeName !== "") {
        tmpFormattingRule = [
            {
                rule: formattingTypeName
            }
        ];
    }
    return tmpFormattingRule;
}

// Function will return the rule title based on the value
export const isValidAmount = (num) => {
    return /^-{0,1}\d*\.{0,1}\d+$/.test(num);
}


// Below date formats are allow for the upload CRM ID for Portal client
export const dateFormatsForCRMIdUpload = [
    { "key": "MM/DD/YYYY", "label": "mm/dd/yyyy" },
    { "key": "M/DD/YYYY", "label": "m/dd/yyyy" },
    { "key": "MM/D/YYYY", "label": "mm/d/yyyy" },
    { "key": "M/D/YYYY", "label": "m/d/yyyy" },

    { "key": "MM-D-YYYY", "label": "mm-d-yyyy" },
    { "key": "M-D-YYYY", "label": "m-d-yyyy" },
    { "key": "MM-DD-YYYY", "label": "mm-dd-yyyy" },
    { "key": "M-DD-YYYY", "label": "m-dd-yyyy" },

    { "key": "DD/MM/YYYY", "label": "dd/mm/yyyy" },
    { "key": "DD/M/YYYY", "label": "dd/m/yyyy" },
    { "key": "D/MM/YYYY", "label": "d/mm/yyyy" },
    { "key": "D/M/YYYY", "label": "d/m/yyyy" },

    { "key": "DD-MM-YYYY", "label": "dd-mm-yyyy" },
    { "key": "DD-M-YYYY", "label": "dd-m-yyyy" },
    { "key": "D-MM-YYYY", "label": "d-mm-yyyy" },
    { "key": "D-M-YYYY", "label": "d-m-yyyy" },

    { "key": "YYYY/MM/DD", "label": "yyyy/mm/dd" },
    { "key": "YYYY/M/DD", "label": "yyyy/m/dd" },
    { "key": "YYYY/MM/DD", "label": "yyyy/mm/dd" },
    { "key": "YYYY/M/DD", "label": "yyyy/m/dd" },

    { "key": "YYYY-MM-DD", "label": "yyyy-mm-dd" },
    { "key": "YYYY-M-DD", "label": "yyyy-m-dd" },
    { "key": "YYYY-MM-DD", "label": "yyyy-mm-dd" },
    { "key": "YYYY-M-DD", "label": "yyyy-m-dd" },

];

// List of timezones to fill in the timezone selection dropdown 
export const timezoneList = [
    { "field_name": "UTC", "field_value": "UTC" },
    { "field_name": "Eastern", "field_value": "America/New_York" },
    { "field_name": "Central", "field_value": "America/Chicago" },
    { "field_name": "Mountain", "field_value": "America/Denver" },
    { "field_name": "Pacific", "field_value": "America/Los_Angeles" }
]

// Function will return true/false by validate the value of frequence.
export const isValidFrequency = (frequencey) => {
    if (typeof frequencey !== 'undefined' && frequencey !== '') {
        return ['', 'one-time', 'recurring'].includes((frequencey.trim()).toLowerCase());
    } else if (typeof frequencey === 'undefined' || frequencey === '' || frequencey === 'none') {
        return true;
    } else {
        return false;
    }
}

// List of random colors to pick for anything
// Currently using it for chart lines.
export const colorList = [
    '#00bcd4',
    '#5cb860',
    '#e53935',
    '#ff9800',
    '#009976',
    '#7B68EE',
    '#8B008B',
    '#2E8B57',
    '#2F4F4F',
    '#DAA520'
]

// Get first date of each months fall between start date and end date
export const getFirstDateOfEachMonth = (startDate, endDate) => {
    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    var start = startDate.split('-');
    var end = endDate.split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
        var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            var month = j + 1;
            var displayMonth = month < 10 ? '0' + month : month;

            dates.push([displayMonth, '01', i].join('-'));
        }
    }
    return dates;
}

// Validate .csv files headers and rows.
export const validateCSVData = (allHeaders = [], allRows = [], validateHeader = "", validationType = "", isOptional = false) => {

    let indexOfHeader = allHeaders.indexOf(validateHeader);
    let isValidData = "";
    if (indexOfHeader > -1) {

        const dateFormatArray = dateFormatsForCRMIdUpload.map(e => e.key);
        
        // eslint-disable-next-line
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let idRegex = /[ `!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/;


        const paymentMethodAllowedValues = ['cc', 'credit', 'credit card', 'card', 'creditcard',
            'visa', 'mastercard', 'master card', 'mc', 'amex', 'american express', 'americanexpress',
            'disco', 'disc', 'discover', 'pp', 'paypal', 'pay pal',
            'ap', 'apple', 'applepay', 'apple pay', 'venmo', 'plaid', 'ach', 'el', 'onl', 'online',
            '', ' ', 'blank', 'none', 'null', 'na', 'n/a', 'check', 'chk', 'cash', 'offline'];

        allRows.forEach(row => {

            // Check if the validation field is optional and contains empty value than doesn't validate it.
            if (isOptional && row[indexOfHeader].trim() === '') {
                isValidData = "";
            } else {    // Else field is optional but contains value in cell than validate the values or if field is not optional than validate the value.
                if (validationType === 'date') {
                    if (!moment(row[indexOfHeader], dateFormatArray, true).isValid()) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'amount') {
                    let tmpAmount = row[indexOfHeader];
                    tmpAmount = tmpAmount.replace(/,/g, '');
                    tmpAmount = tmpAmount.replace(/\$/g, '');
                    if (!isValidAmount(tmpAmount)) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'transaction_id') {
                    let idSpecialChars = idRegex.test(row[indexOfHeader]);
                    if (row[indexOfHeader].trim() === '' || row[indexOfHeader] === '-' || idSpecialChars) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'frequency') {
                    if (!isValidFrequency(row[indexOfHeader])) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'email') {
                    let tempEmail = row[indexOfHeader].toLowerCase();
                    tempEmail = removeSpace(tempEmail);
                    if (!emailRegex.test(tempEmail)) {
                        isValidData = "invalid";
                        console.log("Invalid Email: ", row[indexOfHeader]);
                    }
                } else if (validationType === 'crm_id') {
                    let idSpecialChars = idRegex.test(row[indexOfHeader]);
                    if (row[indexOfHeader].trim() === '' || idSpecialChars) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'paymentMethod') {
                    let paymentMethod = row[indexOfHeader].trim() ? row[indexOfHeader].trim().toLowerCase() : '';
                    if (!paymentMethodAllowedValues.includes(paymentMethod)) {
                        isValidData = "invalid";
                    }
                } else if (validationType === 'postal') {
                    let tempPostal = row[indexOfHeader];
                    if (tempPostal.toString().match(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/) === null) {
                        isValidData = "invalid";
                    }
                }
            }

        });
    } else {
        if (!isOptional) {
            isValidData = "notExist";
        }
    }

    return isValidData;
}

//Remove all spaces.
export const removeSpace = (value) => {
    if (value) {
        value = value.replace(/\s/g, '');
    }
    return value;
}

//add leading zero 
export const addLeadingZero = (value) => {
    if(!isNaN(value) && value !== 'NaN' && parseFloat(value) !== 0) {
        let tempValue = parseFloat(value).toLocaleString();
        let splitTempValue = tempValue.split('.');
    
        if(splitTempValue.length > 1) {
            if(parseInt(splitTempValue[1]) !== 0) {
                if(splitTempValue[1].length === 1) {
                  tempValue += "0";
                }
            } else {
                tempValue = splitTempValue[0];
            }
        }
        return tempValue;
    } else {
        return 0;
    }
  }

// percentage value positive and negative check
// Value Return three type 1, -1, and 0
export const positiveNagativeValueCheck  = (value) => {
    // remove percentage and converted in Integer
    let ValueType = parseFloat(value.slice(0, -1)); 
    let finalValue = Math.sign(ValueType);
    return finalValue;
}

export const commas = (number) => {

    //fix for undefined/null number.
    if (typeof number === "undefined" || number === null) {
        number = 0;
    }

    number = number.toFixed(2);

    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    parts = parts.join(".");
    return parts;

};