import Event from '../../Components/Event/Event'

const onetime_donation = [
    {
        Header: 'One-Time Donations',
        width: 150,
        columns: [
            {
                Header: 'Date',
                accessor: 'unixdate',
                Cell: row => (<strong>{row.value}</strong>),
                width: 134,
            }
        ]
    },
    {
        Header: 'Data',
        width: 690,
        columns: [
            {
                Header: 'Campaign Name',
                accessor: 'campaign_name',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'Google Id',
                accessor: 'ga_id',
                width: 138,
                Cell: (row) => (<strong>{row.value}</strong>),
            },
            {
                Header: 'Amount',
                accessor: 'transaction_amount',
                width: 138,
                Cell: row => (<span>${parseFloat(row.value).toFixed(2).toLocaleString()}</span>)
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: (row) => (<strong>{row.value}</strong>),
                width: 92,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: (row) => (<strong>{row.value}</strong>),
                width: 92,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: (row) => (<strong>{row.value}</strong>),
                width: 92,
            }
        ]
    },
    {
        Header: 'Options',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: '',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }]
    }
];

const recurring_donation = [
    {
        Header: 'Recurring Donation',
        width: 150,
        columns: [
            {
                Header: 'Date',
                accessor: 'unixdate',
                Cell: row => (<strong>{row.value}</strong>),
                width: 134
            }
        ]
    },
    {
        Header: 'Data',
        width: 690,
        columns: [
            {
                Header: 'Campaign Name',
                accessor: 'campaign_name',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            // eslint-disable-next-line
            {
                Header: 'Google ID',
                accessor: 'ga_id',
                width: 138,
                Cell: row => (
                    // eslint-disable-next-line
                    <span><strong>{String(row.value)?.replace(/[\[\]"]/g, '')}</strong></span>
                )
            },
            {
                Header: 'Amount',
                accessor: 'transaction_amount',
                width: 138,
                Cell: row => (
                    <span>${parseFloat(row.value).toFixed(2).toLocaleString()}</span>
                )
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: row => (<strong>{row.value}</strong>),
                width: 92,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: row => (<strong>{row.value}</strong>),
                width: 92,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: row => (<strong>{row.value}</strong>),
                width: 92,
            }
        ]
    },
    {
        Header: 'Options',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: 'type',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }
        ]
    }
];

const general_email_submitted = [
    {
        Header: 'Email Sign-ups',
        width: 150,
        columns: [{
            Header: 'Date',
            accessor: 'unixdate',
            Cell: row => (<strong>{row.value}</strong>),
            width: 134,
        }]
    },
    {
        Header: 'Data',
        width: 690,
        columns: [
            {
                Header: 'Email',
                accessor: 'email',
                Cell: row => (<strong>{row.value}</strong>),
                width: 126,
            },
            {
                Header: 'Google ID',
                accessor: 'ga_id',
                width: 126,
                Cell: row => (
                    <span><strong>{row.value?.replace('user', '')}</strong></span>
                )
            },
            {
                Header: 'Email Sign-up Type',
                accessor: 'email_signup_type',
                width: 126,
                Cell: row => (
                    <span><strong>{row.value?.replace('user', '')}</strong></span>
                ),
                disableFilters: true
            },
            {
                Header: 'URL',
                accessor: 'url',
                Cell: row => (<strong>{row.value}</strong>),
                width: 126,
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: row => (<strong>{row.value}</strong>),
                width: 62,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: row => (<strong>{row.value}</strong>),
                width: 62,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: row => (<strong>{row.value}</strong>),
                width: 62,
            }
        ]
    },
    {
        Header: 'Options',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: 'type',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }]
    }
];

const engaged_20 = [
    {
        Header: 'Engaged 20',
        width: 150,
        columns: [{
            Header: 'Date',
            accessor: 'unixdate',
            Cell: row => (<strong>{row.value}</strong>),
            width: 134,
        }]
    },
    {
        Header: 'Data',
        width: 690,
        columns: [
            {
                Header: 'Google Id',
                accessor: 'ga_id',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'URL',
                accessor: 'url',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                Cell: row => (<strong>{row.value}</strong>),
                width: 138,
            }
        ]
    },
    {
        Header: 'Options',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: 'type',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }
        ]
    }
];

const abandoned_cart = [
    {
        Header: 'Abandoned Cart',
        width: 150,
        columns: [
            { Header: 'Date', accessor: 'unixdate', width: 150, Cell: row => (<strong>{row.value}</strong>) },
        ]
    },
    {
        Header: 'Data',
        width: 700,
        columns: [
            {
                Header: 'Google Id',
                accessor: 'ga_id',
                width: 150,
                Cell: row => (<strong>{row.value}</strong>)
            },
            {
                Header: 'Session Id',
                accessor: 'session_id',
                width: 200,
                Cell: row => (<strong>{row.value}</strong>)
            },
            {
                Header: 'Session Count',
                accessor: 'session_count',
                width: 62,
                Cell: row => (<strong>{row.value}</strong>)
            },
            {
                Header: 'UTM Campaign',
                accessor: 'utm_campaign',
                width: 134,
                Cell: row => (<strong>{row.value}</strong>)
            },
            {
                Header: 'UTM Source',
                accessor: 'utm_source',
                width: 80,
                Cell: row => (<strong>{row.value}</strong>)
            },
            {
                Header: 'UTM Medium',
                accessor: 'utm_medium',
                width: 74,
                Cell: row => (<strong>{row.value}</strong>)
            }
        ]
    },
    {
        Header: 'Options',
        width: 100,
        columns: [{
            Header: 'Options',
            accessor: 'type',
            width: 100,
            Cell: row => (<Event rowData={row} />)
        }]
    }
];

export {
    onetime_donation, recurring_donation, general_email_submitted, engaged_20, abandoned_cart
}