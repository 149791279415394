// packages
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Switch } from "antd";
import dayjs from "dayjs";


//import components
import { ChangeInitial, getClientDashboard } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DashboardCard from "../../Components/DashbaordCard/DashboardCard";
import DashboardChart from "../../Components/DashboardChart/DashboardChart";
import { IdentityApi } from "../../utils/constants";
import axios from "axios";
import moment from "moment";

const { RangePicker } = DatePicker;

const Home = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client, clientDashboardData } = useSelector(({ main }) => main);

  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [mode, setMode] = useState("live");
  const [dates, setDates] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs(),
  });
  const [isGiftArrayEmpty, setIsGiftArrayEmpty] = useState(true);
  const [giftTab, setGiftTab] = useState({
    RechartGiftArray: [],
    giftLeadingSource: "",
    giftLeadingCreative: "",
    giftPercentage: "",
    giftAverageAmount: "",
    totalGifts: "",
    giftsfirsttimedonorcount: "",
    giftsRecurring: "",
    GiftLeadingZipcode: "",
  });
  const [isArrayEmpty, setIsArrayEmpty] = useState(true);
  const [revenueTab, setRevenueTab] = useState({
    RechartArray: [],
    leadingCreative: "",
    leadingSource: "",
    revenuePercentage: "",
    revenueAverageAmount: "",
    revenueTotalAmount: "",
    RevenueLeadingZipcode: "",
  });
  const [cardsDetails, setCardsDetails] = useState({
    topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
    donors: { totalDonors: 0, avgGift: 0 },
    revenue: {
      totalRevenue: 0,
      avgGift: 0,
      conversionRate: 0,
      totalOneTimeRevenue: 0,
      totalRecurringRevenue: 0,
    },
    viewsEngagedTotal: { views: 0, engaged: 0 },
  });

  let channelsCounts = {
    email: {
      name: "Email",
      totalamount: 0,
      totalgift: 0,
    },
    directMail: {
      name: "Direct Mail",
      totalamount: 0,
      totalgift: 0,
    },
    social: {
      name: "Social",
      totalamount: 0,
      totalgift: 0,
    },
    cpc: {
      name: "CPC",
      totalamount: 0,
      totalgift: 0,
    },
    directtraffic: {
      name: "Direct Traffic",
      totalamount: 0,
      totalgift: 0,
    },
    referraltraffic: {
      name: "Referral Traffic",
      totalamount: 0,
      totalgift: 0,
    },
    search: {
      name: "Search",
      totalamount: 0,
      totalgift: 0,
    },
    display: {
      name: "Display",
      totalamount: 0,
      totalgift: 0,
    },
    paidfacebook: {
      name: "Paid Facebook",
      totalamount: 0,
      totalgift: 0,
    },
    other: {
      name: "Other",
      totalamount: 0,
      totalgift: 0,
    },
  };
  let donorCounts = {
    total: 0,
    average: 0,
    amount: 0,
    oneTime: 0,
    recurring: 0,
  };
  let pageViewsEngaged20Total = {
    pageviews: 0,
    engaged_20: 0
  };
  let revenueArray = {
    creativeArray: {},
    sourceArray: {},
    ipPostalArray: {},
    overAll: {},
  };
  let giftArray = {
    creativeArray: {},
    sourceArray: {},
    ipPostalArray: {},
    overAll: {},
  };
  let RevenueArrayData = [];
  let GiftArrayData = [];

  useEffect(() => {
    dispatch(ChangeInitial("Home"));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (client !== "NONE") {
      // getting client dashboard data
      const tableData = {
        client: client, //clientName
        startDate: dates.startDate.format("YYYY-MM-DD"),
        endDate: dates.endDate.format("YYYY-MM-DD"),
      };
      axios
        .post(`${IdentityApi}query/getClientDashboard`, tableData)
        .then((response) => {
          if (response.data.status === "success") {
            // store the Client Dashboard data
            dispatch(getClientDashboard(response.data.data));
          } else {
            window.swal({
              text: "No data found",
              type: "error",
              confirmButtonClass: "btn btn-success",
            });
            resetStateData();
            setIsLoading(false);
            dispatch(getClientDashboard([]));
            setCounter(counter + 1);
          }
        });
    }
  }, [client]);

  useEffect(() => {
    setIsLoading(true);
    setAllData();
  }, [clientDashboardData]);

  const setAllData = () => {
    setIsLoading(true);
    for (const key in clientDashboardData) {
      const element = clientDashboardData[key];
      const tempChannelsCount = JSON.parse(element.topChannelData);
      let pageViewsTotal = JSON.parse(element.pageViewsEngaged20Total);
      pageViewsEngaged20Total.pageviews += pageViewsTotal?.pageviews ? parseInt(pageViewsTotal?.pageviews) : 0;
      pageViewsEngaged20Total.engaged_20 += pageViewsTotal?.engaged_20 ? parseInt(pageViewsTotal?.engaged_20) : 0;
      // *************** Cards Calculations: ***************
      // Card-1: Calculate Top-Channels.
      Object.keys(tempChannelsCount).forEach((channel, index) => {
        let actualChannelName = ["directeMail", "directMail"].includes(channel)
          ? "directMail"
          : channel;
        channelsCounts[actualChannelName].totalamount = parseFloat(
          (
            channelsCounts[actualChannelName].totalamount +
            parseFloat(tempChannelsCount[channel].totalamount)
          ).toFixed(2)
        );
        channelsCounts[actualChannelName].totalgift =
          channelsCounts[actualChannelName].totalgift +
          parseFloat(tempChannelsCount[channel].totalgift);
      });

      // Card-2: Calculate Donors.
      let tempDonors = JSON.parse(element.donors);
      donorCounts.total += tempDonors?.length;
      if (tempDonors?.length) {
        // Card-3: Calculate Revenues.
        let oneTime = tempDonors.filter((e) => e?.onetimerevenue);
        let recurring = tempDonors.filter((e) => e?.recurringrevenue);
        let amounts = tempDonors.filter((e) => e?.amount);
        const oneTimeSum = oneTime?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        const recurringSum = recurring?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        const amountsSum = amounts?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        }, 0);
        donorCounts.oneTime += parseFloat(oneTimeSum);
        donorCounts.recurring += parseFloat(recurringSum);
        donorCounts.amount += parseFloat(amountsSum);
      }

      // *************** Line Chart Calculation: ***************
      // ======== for Leading Creative ========
      // for Revenue & Gift
      let creativeData = JSON.parse(element.creativeData);
      setRevenueGiftArray(
        creativeData,
        revenueArray.creativeArray,
        giftArray.creativeArray,
        element.transaction_date
      );
      // ======== for Leading Source ========
      // for Revenue & Gift
      let sourceData = JSON.parse(element.sourceData);
      setRevenueGiftArray(
        sourceData,
        revenueArray.sourceArray,
        giftArray.sourceArray,
        element.transaction_date
      );
      // ======== for Leading ZipCode ========
      // for Revenue & Gift
      let ipPostalData = JSON.parse(element.ipPostalData);
      setRevenueGiftArray(
        ipPostalData,
        revenueArray.ipPostalArray,
        giftArray.ipPostalArray,
        element.transaction_date
      );

      // for Overall Revenue
      if (tempDonors?.length) {
        tempDonors?.forEach((donor, index) => {
          revenueArray.overAll[element.transaction_date] = {
            count:
              (revenueArray.overAll[element.transaction_date]?.count || 0) + 1,
            amount:
              (revenueArray.overAll[element.transaction_date]?.amount || 0) +
              parseFloat(donor.amount),
          };
          giftArray.overAll[element.transaction_date] = {
            count:
              (revenueArray.overAll[element.transaction_date]?.count || 0) + 1,
            amount:
              (giftArray.overAll[element.transaction_date]?.amount || 0) +
              parseFloat(donor.amount),
          };
        });
      }
    }

    if (Object.values(channelsCounts)?.length > 0) {
      const topItem = Object.values(channelsCounts)?.reduce((prev, current) =>
        +prev.totalamount > +current.totalamount ? prev : current
      );
      cardsDetails["topChannel"].name =
        topItem.totalamount > 0 ? topItem.name : "-";
      cardsDetails["topChannel"].totalAmount =
        topItem.totalamount > 0 ? addLeadingZero(topItem.totalamount) : 0;
      cardsDetails["topChannel"].totalGift =
        topItem.totalamount > 0 ? addLeadingZero(topItem.totalgift) : 0;
    }

    cardsDetails["viewsEngagedTotal"].views = pageViewsEngaged20Total?.pageviews;
    cardsDetails["viewsEngagedTotal"].engaged = pageViewsEngaged20Total?.engaged_20;

    if (Object.values(donorCounts)?.length > 0) {
      // for Calculate Donors.
      const avg =
        donorCounts.amount &&
        (donorCounts.amount / donorCounts.total).toFixed(2);
      donorCounts.average = Number(avg);
      cardsDetails["donors"].totalDonors = donorCounts?.total
        ? addLeadingZero(donorCounts.total)
        : 0;
      cardsDetails["donors"].avgGift = donorCounts?.average
        ? addLeadingZero(donorCounts.average)
        : 0;
      // for Calculate Revenues.
      cardsDetails["revenue"].totalRevenue = donorCounts?.amount
        ? addLeadingZero(donorCounts.amount)
        : 0;
      cardsDetails["revenue"].totalOneTimeRevenue = donorCounts?.oneTime
        ? addLeadingZero(donorCounts.oneTime)
        : 0;
      cardsDetails["revenue"].totalRecurringRevenue = donorCounts?.recurring
        ? addLeadingZero(donorCounts.recurring)
        : 0;
    }
    setCardsDetails(cardsDetails);
    setCounter(counter + 1);

    if (
      Object.keys(revenueArray?.["creativeArray"])?.length ||
      Object.keys(revenueArray?.["sourceArray"])?.length ||
      Object.keys(revenueArray?.["ipPostalArray"])?.length ||
      Object.keys(revenueArray.overAll)?.length ||
      Object.keys(giftArray?.["creativeArray"])?.length ||
      Object.keys(giftArray?.["sourceArray"])?.length ||
      Object.keys(giftArray?.["ipPostalArray"])?.length ||
      Object.keys(giftArray.overAll)?.length
    ) {
      // for Revenue
      const creativeChart = revenueArray["creativeArray"];
      const sourceChart = revenueArray["sourceArray"];
      const ipPostalChart = revenueArray["ipPostalArray"];
      const overAllChart = revenueArray["overAll"];
      // // for Gift
      const creativeGiftChart = giftArray["creativeArray"];
      const sourceGiftChart = giftArray["sourceArray"];
      const ipPostalGiftChart = giftArray["ipPostalArray"];
      const creativeChartData = setRevenueArrayDatewise(creativeChart);
      const sourceChartData = setRevenueArrayDatewise(sourceChart);
      const ipPostalChartData = setRevenueArrayDatewise(ipPostalChart);
      const creativeGiftChartData = setGiftArrayDatewise(creativeGiftChart);
      const sourceGiftChartData = setGiftArrayDatewise(sourceGiftChart);
      const ipPostalGiftChartData = setGiftArrayDatewise(ipPostalGiftChart);
      // for revenue
      const creativemaxValue = creativeChartData?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.amount > current.amount ? prev : current;
      });
      const sourcemaxValue = sourceChartData?.reduce(function (prev, current) {
        return prev && prev.amount > current.amount ? prev : current;
      });
      const ipPostalmaxValue = ipPostalChartData?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.amount > current.amount ? prev : current;
      });
      // for gift
      const creativemaxGiftValue = creativeGiftChartData?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.gift > current.gift ? prev : current;
      });
      const sourcemaxGiftValue = sourceGiftChartData?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.gift > current.gift ? prev : current;
      });
      const ipPostalmaxGiftValue = ipPostalGiftChartData?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.gift > current.gift ? prev : current;
      });

      // for overAll & average
      let overAllChartArr = [];
      for (const property in overAllChart) {
        const el = overAllChart[property];
        overAllChartArr.push({
          date: property,
          count: el.count,
          amount: el.amount,
        });
      }
      const overAllmaxValue = overAllChartArr?.reduce(function (prev, current) {
        return prev && prev.amount > current.amount ? prev : current;
      });
      const overAllmaxGiftValue = overAllChartArr?.reduce(function (
        prev,
        current
      ) {
        return prev && prev.count > current.count ? prev : current;
      });
      const overAllRevenueAvg = overAllmaxValue
        ? (overAllmaxValue.amount / overAllmaxValue.count).toFixed(2)
        : 0;
      const overAllGiftAvg = overAllmaxGiftValue
        ? (overAllmaxGiftValue.amount / overAllmaxGiftValue.count).toFixed(2)
        : 0;

      daysDiffData(
        overAllChartArr,
        sourceChartData,
        creativeChartData,
        ipPostalChartData,
        overAllChartArr,
        sourceGiftChartData,
        creativeGiftChartData,
        ipPostalGiftChartData
      );

      if (
        overAllmaxValue?.amount > 0 ||
        sourcemaxValue?.amount > 0 ||
        creativemaxValue?.amount > 0
      ) {
        setIsArrayEmpty(false);
      }
      if (
        overAllmaxGiftValue?.count > 0 ||
        sourcemaxGiftValue?.amount > 0 ||
        creativemaxGiftValue?.amount > 0
      ) {
        setIsGiftArrayEmpty(false);
      }
      setRevenueTab({
        RechartArray: RevenueArrayData,
        leadingCreative: creativemaxValue?.name ? creativemaxValue?.name : "",
        leadingSource: sourcemaxValue?.name ? sourcemaxValue?.name : "",
        revenueAverageAmount: overAllRevenueAvg
          ? "$" + addLeadingZero(overAllRevenueAvg)
          : 0,
        revenueTotalAmount: overAllmaxValue?.amount
          ? "$" + addLeadingZero(overAllmaxValue?.amount)
          : 0,
        RevenueLeadingZipcode: ipPostalmaxValue?.name
          ? ipPostalmaxValue?.name
          : "",
      });
      setGiftTab({
        RechartGiftArray: GiftArrayData,
        giftLeadingSource: sourcemaxGiftValue?.name
          ? sourcemaxGiftValue?.name
          : "",
        giftLeadingCreative: creativemaxGiftValue?.name
          ? creativemaxGiftValue?.name
          : "",
        giftAverageAmount: overAllGiftAvg
          ? "$" + addLeadingZero(overAllGiftAvg)
          : 0,
        totalGifts: overAllmaxGiftValue?.count
          ? addLeadingZero(overAllmaxGiftValue?.count)
          : 0,
        GiftLeadingZipcode: ipPostalmaxGiftValue?.name
          ? ipPostalmaxGiftValue?.name
          : "",
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      resetStateData();
    }
  };

  const addLeadingZero = (value) => {
    if (value) {
      let tempValue = value.toLocaleString();
      let splitTempValue = String(tempValue).split(".");
      if (splitTempValue?.length > 1) {
        if (splitTempValue[1]?.length === 1) {
          tempValue += "0";
        }
      }
      return tempValue;
    } else {
      return value;
    }
  };

  // reduce array for Leading Data in Chart.
  const reduceArray = (value) => {
    if (value) {
      const maxValue = Object.keys(value)?.reduce(function (prev, current) {
        return prev && value[prev].amount > value[current].amount
          ? prev
          : current;
      });
      return maxValue;
    } else {
      return false;
    }
  };
  // const reduceOverAllArray = (value) => {
  //   if (value?.length) {
  //     const maxValue = value?.reduce(function (prev, current) {
  //       return prev && prev.amount > current.amount ? prev : current;
  //     });
  //     return maxValue;
  //   } else {
  //     return false;
  //   }
  // };

  // set Array for Revenue/Gift
  const setRevenueGiftArray = (value, revenueArr, giftArr, date) => {
    if (Object.keys(value)?.length) {
      // for Revenue
      for (const property in value) {
        if (property !== "Others") {
          revenueArr[date] = {
            ...revenueArr[date],
            ...{
              [property]: {
                count:
                  (revenueArr[date]?.[property]?.count || 0) +
                  value[property].count,
                amount:
                  (revenueArr[date]?.[property]?.amount || 0) +
                  value[property].totalamount,
              },
            },
          };
        }
      }
      // for Gift
      for (const property in value) {
        if (property !== "Others") {
          giftArr[date] = {
            ...giftArr[date],
            ...{
              [property]: {
                gift:
                  (giftArr[date]?.[property]?.gift || 0) +
                  value[property].count,
              },
            },
          };
        }
      }
    }
  };

  // set Array for Revenuedatewise
  const setRevenueArrayDatewise = (value) => {
    let arr = [];
    if (Object.keys(value)?.length) {
      for (const property in value) {
        const data = reduceArray(value[property]);
        arr.push({
          date: property,
          name: data,
          amount: value[property][data].amount,
        });
      }
      return arr;
    }
  };
  // set Array for Giftdatewise
  const setGiftArrayDatewise = (value) => {
    let arr = [];
    if (Object.keys(value)?.length) {
      for (const property in value) {
        const data = value[property];
        const maxValue = Object.keys(data)?.reduce(function (prev, current) {
          return prev && data[prev].gift > data[current].gift ? prev : current;
        });
        arr.push({
          date: property,
          name: maxValue,
          gift: value[property][maxValue].gift,
        });
      }
      return arr;
    }
  };

  const daysDiffData = (
    revenue,
    source,
    creative,
    ipPostal,
    gifts,
    gift_source,
    gift_creative,
    gift_ipPostal
  ) => {

    const startDate = dayjs(dates.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(dates.endDate).format('YYYY-MM-DD');
  
    let dateDifference = moment(endDate).diff(moment(startDate), 'days');
    for (let i = 0; i <= dateDifference; i++) {
      // Get the current date
      let currentDate = moment(startDate).add(i, "days").format('YYYY-MM-DD');
      let convertedDate = moment(currentDate).format("YYYY-MM-DD");

      const revenueCheck =
        revenue && revenue?.filter((e) => e.date === convertedDate);
      const sourceCheck =
        source && source?.filter((e) => e.date === convertedDate);
      const creativeCheck =
        creative && creative?.filter((e) => e.date === convertedDate);
      const ipPostalCheck =
        ipPostal && ipPostal?.filter((e) => e.date === convertedDate);
      const giftsCheck = gifts && gifts?.filter((e) => e.date === convertedDate);
      const gift_sourceCheck =
        gift_source && gift_source?.filter((e) => e.date === convertedDate);
      const gift_creativeCheck =
        gift_creative && gift_creative?.filter((e) => e.date === convertedDate);
      const gift_ipPostalCheck =
        gift_ipPostal && gift_ipPostal?.filter((e) => e.date === convertedDate);
      // add values in array
      RevenueArrayData.push({
        date: moment(currentDate).format('MM-DD-YYYY'),
        Revenue: revenueCheck && revenueCheck[0]?.amount || 0,
        Source: sourceCheck && sourceCheck[0]?.amount || 0,
        Creative: creativeCheck && creativeCheck[0]?.amount || 0,
        ipPostal: ipPostalCheck && ipPostalCheck[0] ? ipPostalCheck[0]?.amount : 0,
      });
      GiftArrayData.push({
        date: moment(currentDate).format('MM-DD-YYYY'),
        Gifts: giftsCheck && giftsCheck[0]?.count || 0,
        Source: gift_sourceCheck && gift_sourceCheck[0]?.gift || 0,
        Creative: gift_creativeCheck && gift_creativeCheck[0]?.gift || 0,
        ipPostal: gift_ipPostalCheck && gift_ipPostalCheck[0] ? gift_ipPostalCheck[0]?.gift : 0,
      });
    }
  };

  //Date-Range change function.
  const handleDateChange = async (date, dateString) => {
    setDates({
      startDate: dayjs(dateString[0]),
      endDate: dayjs(dateString[1]),
    });
    if (dateString && dateString[0] && dateString[1]) {
      // getting client dashboard data
      setIsLoading(true);
      const tableData = {
        client: client, //clientAcronym
        startDate: dayjs(dateString[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateString[1]).format("YYYY-MM-DD"),
      };
      const response = await axios.post(
        `${IdentityApi}query/getClientDashboard`,
        tableData
      );
      if (response.data.status === "success") {
        // store the Client Dashboard data
        dispatch(getClientDashboard(response.data.data));
      } else {
        window.swal({
          text: "No data found",
          type: "error",
          confirmButtonClass: "btn btn-success",
        });
        resetStateData();
        setIsLoading(false);
        dispatch(getClientDashboard([]));
        setCounter(counter + 1);
      }
    }
  };

  // for reset state value
  const resetStateData = () => {
    setCardsDetails({
      topChannel: { name: "-", totalAmount: 0, totalGift: 0 },
      donors: { totalDonors: 0, avgGift: 0 },
      revenue: {
        totalRevenue: 0,
        avgGift: 0,
        conversionRate: 0,
        totalOneTimeRevenue: 0,
        totalRecurringRevenue: 0,
      },
      viewsEngagedTotal: { views: pageViewsEngaged20Total.pageviews, engaged: pageViewsEngaged20Total.engaged_20 },
    });
    daysDiffData(0, 0, 0, 0, 0, 0, 0, 0);
    setIsArrayEmpty(true);
    setIsGiftArrayEmpty(true);
    setRevenueTab({
      RechartArray: RevenueArrayData,
      leadingCreative: "",
      leadingSource: "",
      revenueAverageAmount: 0,
      revenueTotalAmount: 0,
      RevenueLeadingZipcode: "",
    });
    setGiftTab({
      RechartGiftArray: GiftArrayData,
      giftLeadingSource: "",
      giftLeadingCreative: "",
      giftAverageAmount: 0,
      totalGifts: 0,
      GiftLeadingZipcode: "",
    });
    setCounter(counter + 1);
  };

  // Mode change 'live' and 'test' default live.
  const handleChangeMode = (event) => {
    let modeNew = event === true ? "live" : "test";
    setMode(modeNew);
    if (modeNew === "test") {
      resetStateData();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setAllData();
    }
  };

  return (
    <div className="container-fluid">
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <div className="dashboard">
          {/* Date Range Implimented for Card and Chart */}
          <div className="date-range card">
            <div className="card-content">
              <div className="row">
                <div className="col-md-9 col-sm-9">
                  <RangePicker
                    defaultValue={[dates.startDate, dates.endDate]}
                    onChange={handleDateChange}
                    allowClear={false}
                    size={"middle"}
                    bordered={"false"}
                    format={"MMMM D, YYYY"}
                    separator={
                      <i className="material-icons">arrow_right_alt</i>
                    }
                  />
                </div>
                <div className="col-md-3 col-sm-3 text-right date-range">
                  <p className="transaction-data-mode-switch">
                    <Switch
                      size={"default"}
                      checked={mode === "live" ? true : false}
                      onChange={handleChangeMode}
                    />{" "}
                    Live data
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Card Component */}
          <DashboardCard
            key={`dash-${counter}`}
            topChannel={cardsDetails.topChannel}
            donors={cardsDetails.donors}
            revenue={cardsDetails.revenue}
            pageViewTotal={cardsDetails.viewsEngagedTotal}
            isLoading={isLoading}
          />

          {/* Chart Component */}
          <DashboardChart
            key={`chart-${counter}`}
            revenueTab={revenueTab}
            isArrayEmpty={isArrayEmpty}
            giftTab={giftTab}
            isGiftArrayEmpty={isGiftArrayEmpty}
            endDate={dates.endDate}
            startDate={dates.startDate}
            isLoading={isLoading}
          />

        </div>
      )}
    </div>
  );
};

export default Home;