// packages
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//import components
import { ChangeInitial } from '../../redux/Main/action';
import AvgCharts from '../../Components/AvgCharts/AvgCharts';
import DistributionTbl from '../../Components/DistributionTbl/DistributionTbl';


import ClientChoose from "../Clients/chooseClient";


const DistributionTable = () => {

    const { isAuthenticated } = useAuth0();
    const { role, client } = useSelector(({ main }) => main);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ChangeInitial('Distribution Table'))
    }, [dispatch]);

    return (

        <div className="container-fluid">

            {isAuthenticated && role === 'admin' && (

                <ClientChoose alertType="alert alert-success">
                    <span>Welcome <strong>Team Member</strong>. Please choose a client to view/edit their data.</span>
                </ClientChoose>

            )}
            {
                client !== 'NONE' &&
                <div className='distribution-table'>
                    <AvgCharts />
                    <DistributionTbl />
                </div>
            }
        </div>
    )
}

export default DistributionTable;