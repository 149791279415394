import React from 'react';
import { useTable, usePagination, useFilters, useResizeColumns, useSortBy } from 'react-table';
import ReactTablePageResizer from './reactTablePageResizer';

const TextFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
        <input
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
            style={{ width: '100%' }}
        />
    );
}

const ReactTableUI = ({
    data,
    columns,
    isLoading,
    showFilter = true,
    showPaginationOnTop = true,
    showPaginationOnBottom = false,
    defaultPageSize = 5,
    hiddenColumns = [],
    pageSizeOptions = true,
    paginationApiCall = false,
    nextPageEvent = "",
    previousPageEvent = "",
    nextArrow = true,
    previousArrow = false,
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page, // Instead of using 'rows', we now use 'page'
        prepareRow,
        state: { pageCount, pageSize, pageIndex }, // Get the current page index and page size
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        gotoPage
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns }, // Set the initial page index and page size
        },
        useFilters,
        useResizeColumns,
        useSortBy,
        usePagination, // Use the usePagination hook
    );

    const pageCountAfterFiltering = Math.ceil(rows.length / pageSize);

    const handlePageChange = (e) => {
        const newPageIndex = Number(e.target.value) - 1; // Subtract 1 to convert to zero-based index
        gotoPage(newPageIndex);
    };

    let bodyWidth = 0;

    if (headerGroups.length) {
        bodyWidth = headerGroups.map((headerGroup) => {
            bodyWidth = headerGroup.headers.reduce((total, item) => total + (item.width || 0), 0);
            return bodyWidth;
        })
    }

    return (
        <div id='pages-subPages-table'>
            <div className='ReactTable -striped -highlight'>
                {showPaginationOnTop && (
                    <ReactTablePageResizer
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={pageSize}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageCountAfterFiltering={pageCountAfterFiltering}
                        canNextPage={canNextPage}
                        pageSizeOptions={pageSizeOptions}
                        paginationApiCall={paginationApiCall}
                        nextPageEvent={nextPageEvent}
                        page={page}
                        previousPageEvent={previousPageEvent}
                        nextArrow={nextArrow}
                        previousArrow={previousArrow}
                    />
                )}

                <table {...getTableProps()} className='rt-table' role='grid' style={{ display: 'flex', flexDirection: 'column' }}>
                    <thead className='rt-thead -header' style={{ minWidth: `${bodyWidth[1]}px`, overflow: "hidden" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex' }}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={`rt-th rt-resizable-header -cursor-pointer ${column.isSorted ? (column.isSortedDesc ? '-sort-desc' : '-sort-asc') : ''}`}
                                        role='columnheader'
                                        {...column.cellProps}
                                        style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0, overflow: "hidden" }}
                                    >
                                        <div className='rt-resizable-header-content'>
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                            />
                                            <span>{column.render('Header')}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {showFilter === true && <thead className='rt-thead -filters' style={{ minWidth: `${bodyWidth[1]}px` }}>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} className='rt-tr' role='row' {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', overflow: "hidden" }}>
                                {headerGroup.headers.map((column, i) => (
                                    <th className={`rt-th`} key={i} style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                        {column.canFilter && <TextFilter column={column} />}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>}
                    <tbody {...getTableBodyProps()} className='rt-tbody' style={{ minWidth: `${bodyWidth[1]}px` }}>
                        {page.length > 0 && page.map((row, index) => {
                            prepareRow(row);
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} {...row.getRowProps()} className={`rt-tr ${className}`} style={{ display: 'flex' }}>
                                    {row.cells.map((cell, ind) => (
                                        <td key={ind} className='rt-td' style={{ flexBasis: `${cell.column.width}px`, flexGrow: 1, flexShrink: 0 }}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {page.length <= 0 && [1, 2, 3].map((row, index) => {
                            let className = index % 2 === 0 ? '-odd' : '';
                            return (
                                <tr key={index} className={`rt-tr ${className}`} style={{ display: 'flex' }}>
                                    {headerGroups[0].headers.map((column, index) => (
                                        <td key={index} className='rt-td' style={{ flexBasis: `${column.width}px`, flexGrow: 1, flexShrink: 0 }}>
                                            <span className='noColourText'>NA</span>
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {page.length <= 0 && <div className="rt-noData" style={showFilter ? { margin: "3em" } : { margin: "2em" }} >No rows found</div>}

                {isLoading && <div className="-loading -active"><div className="-loading-inner">Loading...</div></div>}

                {showPaginationOnBottom && (
                    <ReactTablePageResizer
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        nextPage={nextPage}
                        defaultPageSize={pageSize}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageCountAfterFiltering={pageCountAfterFiltering}
                        canNextPage={canNextPage}
                        pageSizeOptions={pageSizeOptions}
                        paginationApiCall={paginationApiCall}
                        nextPageEvent={nextPageEvent}
                        page={page}
                        previousPageEvent={previousPageEvent}
                        nextArrow={nextArrow}
                        previousArrow={previousArrow}
                    />
                )}
            </div>
        </div>
    )
}

export default ReactTableUI;