// packages
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";

// Import Columns
import {
  onetime_donation as oneTimeDonations,
  recurring_donation as recurringDonations,
} from "../ClientData/actions";

//import components
import { ChangeInitial } from "../../redux/Main/action";
import ClientChoose from "../Clients/chooseClient";
import DynamicCards from "../Shared/DynamicCards";

const GiveEvents = () => {

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { role, client } = useSelector(({ main }) => main);

  useEffect(() => {
    dispatch(ChangeInitial("Give Events"));
  }, [dispatch]);

  return (
    <div className="container-fluid">
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}
      {client !== "NONE" && (
        <div className="dashboard dynamic-card">

          {/* One Time Donations Card */}
          <DynamicCards
            title="One Time Donations"
            formType="onetime_donation"
            background="linear-gradient(60deg, rgb(255, 200, 69), rgb(255, 158, 27))"
            headerIcon="attach_money"
            columns={oneTimeDonations}
            card={["Person", "Revenue", "Device"]}
          />

          <hr className="dasboard-hr" />

          {/* Recurring Donations Card */}
          <DynamicCards
            title="Recurring Donations"
            formType="recurring_donation"
            background="linear-gradient(60deg, rgb(239, 83, 80), rgb(229, 57, 53))"
            headerIcon="attach_money"
            columns={recurringDonations}
            card={["Person", "Revenue", "Device"]}
          />

        </div>
      )}
    </div>
  );
};

export default GiveEvents;