import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ChangeInitial } from '../../redux/Main/action';
import { abandoned_cart } from '../ClientData/actions';
import ClientChoose from '../Clients/chooseClient';
import DynamicCards from '../Shared/DynamicCards'

const AbandonedGiftsLightbox = () => {

  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const { role, client } = useSelector(({ main }) => main);


  useEffect(() => {
    dispatch(ChangeInitial("Abandoned Gifts Lightbox"));
  }, []);

  return (
    <div className='abandoned-cart dynamic-card'>
      {isAuthenticated && role === "admin" && (
        <ClientChoose alertType="alert alert-success">
          <span>
            Welcome <strong>Team Member</strong>. Please choose a client to
            view/edit their data.
          </span>
        </ClientChoose>
      )}

      {client !== "NONE" && (
        <div className="dashboard">

          {/* Abandoned Cart Card */}
          <DynamicCards
            title="Abandoned Gift Explorer"
            formType="abandoned_cart"
            background="linear-gradient(60deg, rgb(76, 175, 80), rgb(33 98 36))"
            columns={abandoned_cart}
            card={["Person"]}
          />

        </div>
      )}

    </div>
  )
}
export default AbandonedGiftsLightbox;
