import React from 'react'

const ReactTablePageResizer = ({
    previousPage,
    canPreviousPage,
    handlePageChange,
    setPageSize,
    nextPage,
    defaultPageSize,
    gotoPage,
    pageIndex,
    pageCount,
    pageCountAfterFiltering,
    canNextPage
}) => {
    return (
        <div className='pagination-top'>
            <div className='-pagination'>
                <div className='-previous'>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className='-btn' type="button">
                        Previous
                    </button>
                </div>
                <div className='-center'>
                    <span className='-pageInfo'>
                        Page
                        <div className='-pageJump'>
                            <input type="number" value={pageIndex + 1} onChange={handlePageChange} min={1} max={pageCount} />
                        </div>
                        <span> of </span>
                        <span className="-totalPages">{pageCountAfterFiltering || 1}</span>
                    </span>
                    <span className='select-wrap -pageSizeOptions'>
                        <select onChange={(e) => { setPageSize(parseInt(e.target.value)); gotoPage(0); }} defaultValue={defaultPageSize}>
                            {[5, 10, 20, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                <div className='-next'>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className='-btn' type="button">
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ReactTablePageResizer;
