import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const SidebarSuper = () => {
  const client = useSelector(({ main }) => main.client);
  const [activeLink, setActiveLink] = useState("");
  let currentUrl = window.location.pathname;
  
  useEffect(() => {
    if (client) {
      setActiveLink("/admin/identity-dashboard")
    }
  }, [client])
  
  useEffect(() => {
    setActiveLink(currentUrl);
  }, [])

  return (
    <ul className="nav">
      <li>
        <NavLink
          style={activeLink === "/admin/identity-dashboard" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/identity-dashboard"
          title="Home"
          onClick={() => setActiveLink("/admin/identity-dashboard")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/identity-dashboard" ? { color: 'white' } : {}} >dashboard</i>
          <p>Dashboard</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/distribution-table" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/distribution-table"
          title="Distribution Table"
          onClick={() => setActiveLink("/admin/distribution-table")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/distribution-table" ? { color: 'white' } : {}} >table</i>
          <p>Distribution Table</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/give-events" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/give-events"
          title="Give Events"
          onClick={() => setActiveLink("/admin/give-events")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/give-events" ? { color: 'white' } : {}} >attach_money</i>
          <p>Giving Events</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/email-sign-up-events" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/email-sign-up-events"
          title="Email Sign-Up Events"
          onClick={() => setActiveLink("/admin/email-sign-up-events")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/email-sign-up-events" ? { color: 'white' } : {}} >drafts</i>
          <p>Email Sign-Up Events</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/engaged-sessions" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/engaged-sessions"
          title="Engaged Sessions"
          onClick={() => setActiveLink("/admin/engaged-sessions")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/engaged-sessions" ? { color: 'white' } : {}} >face</i>
          <p>Engaged Sessions</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          style={activeLink === "/admin/abandoned-gifts-lightbox" ? { color: 'white', background: '#ab1d44' } : {}}
          to="/admin/abandoned-gifts-lightbox"
          title="Abandoned Gifts Lightbox"
          onClick={() => setActiveLink("/admin/abandoned-gifts-lightbox")}
        >
          <i className="material-icons"
            style={activeLink === "/admin/abandoned-gifts-lightbox" ? { color: 'white' } : {}} >remove_shopping_cart</i>
          <div style={{fontSize:'15px'}}>Abandoned Gifts Lightbox</div>
        </NavLink>
      </li>
    </ul>
  );
}

export default SidebarSuper;