import React, { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { addLeadingZero } from "../../Helper/helper";
import Rechart from "../Rechart/Rechart";
import { SkeletonLine } from "../../Views/UI/skeletonLoader";
import dayjs from "dayjs";


const DashboardChart = ({
    revenueTab,
    isArrayEmpty,
    giftTab,
    isGiftArrayEmpty,
    // visitorTab,
    // isVisitorArrayEmpty,
    startDate,
    endDate,
    isLoading
}) => {

    const lineAttributes = {
        "Revenue": [{
            "dataKey": "Revenue",
            "stroke": "#006272"
        }, {
            "dataKey": "Source",
            "stroke": "#ff9e1b"
        }, {
            "dataKey": "Creative",
            "stroke": "#d50032"
        }, {
            "dataKey": "ipPostal",
            "stroke": "#0093b2"
        }],
        "Gifts": [{
            "dataKey": "Gifts",
            "stroke": "#d50032"
        }, {
            "dataKey": "Source",
            "stroke": "#ff9e1b"
        }, {
            "dataKey": "Creative",
            "stroke": "#006272"
        }, {
            "dataKey": "ipPostal",
            "stroke": "#0093b2"
        }],
        "Visitors": [{
            "dataKey": "Visitors",
            "stroke": "#006272"
        }, {
            "dataKey": "Gifts",
            "stroke": "#d50032"
        }, {
            "dataKey": "Source",
            "stroke": "#ff9e1b"
        }, {
            "dataKey": "Creative",
            "stroke": "#512d6d"
        }, {
            "dataKey": "ipPostal",
            "stroke": "#0093b2"
        }]
    };

    const [activeTab, setActiveTab] = useState("Revenue");
    const [currentChartLines, setCurrentChartLines] = useState(lineAttributes.Revenue);
    const [data, setData] = useState([]);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    // const [impressionsDateLimit, setImpressionsDateLimit] = useState(moment().set({ 'year': 2023, 'month': 7, 'date': 1 }));
    // const [hideImpressionDependantContent, setHideImpressionDependantContent] = useState(false);

    const tabData = {
        Revenue: revenueTab?.RechartArray,
        Gifts: giftTab?.RechartGiftArray,
        // Visitors: visitorTab?.RechartVisitorArray
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentChartLines(lineAttributes[tab])
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setData(tabData[activeTab]);
            setIsDataEmpty(activeTab === "Revenue" ? isArrayEmpty : (activeTab === "Gifts" ? isGiftArrayEmpty : ''));
            // const daysDifference = moment(startDate).diff(impressionsDateLimit, 'days');
            // daysDifference < 0 ? setHideImpressionDependantContent(true) : setHideImpressionDependantContent(false);
        }, 5);

        return () => clearTimeout(timer);
    }, [activeTab, isLoading, revenueTab, giftTab]);


    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={'12px'}>
                    {payload.value}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="date">{`${payload[0].payload.date}`}</p>
                    {activeTab === 'Revenue' && (
                        <div>
                            <p className="revenue">{`Overall Revenue: $${addLeadingZero(payload[0].payload.Revenue)}`}</p>
                            <p className="source">{`Leading Source: $${addLeadingZero(payload[0].payload.Source)}`}</p>
                            <p className="creative">{`Leading Creative: $${addLeadingZero(payload[0].payload.Creative)}`}</p>
                            <p className="zipcode">{`Leading ZipCode: $${addLeadingZero(payload[0].payload.ipPostal)}`}</p>
                        </div>
                    )}
                    {activeTab === 'Gifts' && (
                        <div>
                            <p className="revenue">{`Gifts: ${addLeadingZero(payload[0].payload.Gifts)}`}</p>
                            <p className="source">{`Leading Source: ${addLeadingZero(payload[0].payload.Source)}`}</p>
                            <p className="creative">{`Leading Creative: ${addLeadingZero(payload[0].payload.Creative)}`}</p>
                            <p className="zipcode">{`Leading ZipCode: ${addLeadingZero(payload[0].payload.ipPostal)}`}</p>
                        </div>
                    )}
                    {/* {activeTab === 'Visitors' && (
                        <div>
                            <p className="revenue">{`Visitors: ${addLeadingZero(payload[0].payload.Visitors)}`}</p>
                            <p className="conversions">{`Gifts: ${addLeadingZero(payload[0].payload.Gifts)}`} {`(${addLeadingZero(payload[0].payload.Conversions)}%)`}</p>
                            <p className="source">{`Leading Source: ${addLeadingZero(payload[0].payload.Source)}`}</p>
                            <p className="creative">{`Leading Creative: ${addLeadingZero(payload[0].payload.Creative)}`}</p>
                            <p className={`zipcode ${hideImpressionDependantContent ? `hide` : ''}`}>{`Leading ZIP Code: ${addLeadingZero(payload[0].payload.ipPostal)}`}</p>
                        </div>
                    )} */}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="dashboard-chart row">
            <div className="col-sm-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="linear-gradient(60deg,#512D6D,#441b5d)">
                        <i className="material-icons">timeline</i>
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col-sm-9 col-md-8 col-md-offset-1 col-sm-offset-2 chart-header">
                                <h4 className="card-title">{activeTab} - {dayjs(startDate).format('MMMM Do, YYYY')} - {dayjs(endDate).format('MMMM Do, YYYY')}</h4>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="row">
                                <div className="col-md-12" style={{marginTop:'50px'}}>
                                    <div className="skeleton skeleton-chart">
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '20%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '100%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '50%', width: '4%' }} />
                                        <SkeletonLine style={{ height: '45%', width: '4%' }} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row">
                                    <div className="line-chart-container col-sm-12 col-md-9">
                                        {!isDataEmpty ?
                                            <ResponsiveContainer width="100%" height={400}>
                                                <LineChart data={data}>
                                                    <XAxis
                                                        allowDataOverflow
                                                        dataKey="date"
                                                        tick={<CustomizedAxisTick />}
                                                        domain={['dataMin', 'dataMax']}
                                                        interval={data.length < 31 ? 0 : Math.round(data.length / 30)}
                                                        height={70}
                                                        padding={{
                                                            right: 6,
                                                            left: 4,
                                                        }}
                                                    />
                                                    <YAxis
                                                        tickFormatter={(value) => activeTab === "Revenue" ? `$${addLeadingZero(value)}` : addLeadingZero(value)}
                                                        allowDataOverflow={true}
                                                        interval="preserveStartEnd"
                                                        width={100}
                                                        padding={{
                                                            top: 10,
                                                            bottom: 4
                                                        }}
                                                    />

                                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} horizontal={false} />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    {currentChartLines.map((line, index) => (
                                                        <Line key={index} strokeWidth={2.5} type="monotone" dot={true} dataKey={line.dataKey} stroke={line.stroke} />
                                                    ))}
                                                </LineChart>
                                            </ResponsiveContainer>
                                            : <Rechart
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        }
                                    </div>
                                    <div className="tab-container col-sm-12 col-md-3">
                                        <div className="btn-group btn-group-sm">
                                            <button className={`btn tabs ${activeTab === "Revenue" && 'tabshover'}`} onClick={() => handleTabChange("Revenue")}>
                                                Revenue
                                            </button>
                                            <button className={`btn tabs ${activeTab === "Gifts" && 'tabshover'}`} onClick={() => handleTabChange("Gifts")}>
                                                Gifts
                                            </button>
                                            {/* <button className={`btn tabs ${activeTab === "Visitors" && 'tabshover'}`} onClick={() => handleTabChange("Visitors")}>
                                                Visitors
                                            </button> */}
                                        </div>
                                    </div>
                                    <div className="dashbaord-chart-containt col-sm-12 col-md-3">
                                        {activeTab === "Revenue" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5"><span className="icons-containt">Overall Revenue </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="source" />
                                                    <div className="ml-5 leading-data"> <span className="icons-containt">Leading Source = <span className="leading-containt"> {(revenueTab.leadingSource && (revenueTab.leadingSource === "Others" ? 'N/A' : revenueTab.leadingSource)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Creative = <span className="leading-containt"> {(revenueTab.leadingCreative && (revenueTab.leadingCreative === "Others" ? 'N/A' : revenueTab.leadingCreative)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="zipcode" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading ZipCode = <span className="leading-containt"> {(revenueTab.RevenueLeadingZipcode && (revenueTab.RevenueLeadingZipcode === "Others" ? 'N/A' : revenueTab.RevenueLeadingZipcode)) || 'N/A'} </span> </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "Gifts" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5"><span className="icons-containt">Gifts</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="source" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Source = <span className="leading-containt"> {(giftTab.giftLeadingSource && (giftTab.giftLeadingSource === "Others" ? 'N/A' : giftTab.giftLeadingSource)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Creative = <span className="leading-containt"> {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative === "Others" ? 'N/A' : giftTab.giftLeadingCreative)) || 'N/A'} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="zipcode" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading ZipCode = <span className="leading-containt"> {(giftTab.GiftLeadingZipcode && (giftTab.GiftLeadingZipcode === "Others" ? 'N/A' : giftTab.GiftLeadingZipcode)) || 'N/A'} </span> </span></div>
                                                </div>
                                            </div>
                                        )}
                                        {/* {activeTab === "Visitors" && (
                                            <div className="tabs-main-container">
                                                <div className="tabs-containt">
                                                    <div className="revenue" />
                                                    <div className="ml-5"><span className="icons-containt">Visitors</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="creative" />
                                                    <div className="ml-5"><span className="icons-containt">Gifts (Conversion Rate)</span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="source" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt"> Leading Source = <span className="leading-containt"> {(visitorTab.visitorsLeadingSource || 'N/A')} </span> </span></div>
                                                </div>
                                                <div className="tabs-containt">
                                                    <div className="conversions" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading Creative = <span className="leading-containt">  {(visitorTab.visitorsLeadingCreative || 'N/A')} </span> </span></div>
                                                </div>
                                                <div className={`tabs-containt ${hideImpressionDependantContent ? `hide` : ``}`}>
                                                    <div className="zipcode" />
                                                    <div className="ml-5 leading-data"><span className="icons-containt">Leading ZipCode = <span className="leading-containt"> {(visitorTab.VisitorLeadingZipcode || 'N/A')} </span> </span></div>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9 col-sm-12 footer-rechart">
                                        <div className="footer-rechart">
                                            <i className="material-icons">lightbulb_outline</i>
                                            {activeTab === "Revenue" && (<div>Your top earner, <strong>{(revenueTab.leadingCreative && (revenueTab.leadingCreative == "Others" ? 'N/A' : revenueTab.leadingCreative) || 'N/A')}</strong> creative, brought in <strong>{(revenueTab.revenueTotalAmount || 'N/A')}</strong> total with an average of <strong>{(revenueTab.revenueAverageAmount || 'N/A')}</strong> per gift.</div>)}
                                            {activeTab === "Gifts" && (<div>Your top gift magnet, <strong> {(giftTab.giftLeadingCreative && (giftTab.giftLeadingCreative == "Others" ? 'N/A' : giftTab.giftLeadingCreative) || 'N/A')}</strong> creative, saw <strong>{giftTab.giftLeadingCreative ? giftTab.totalGifts : 'N/A'}</strong> gifts (averaging <strong> {(giftTab.giftAverageAmount || 'N/A')} </strong>per gift).</div>)}
                                            {/* {activeTab === "Visitors" && (<div><strong> {(visitorTab.VisitorLeadingZipcode || 'N/A')} </strong> is the zip code that drove the most traffic, driving <strong>{visitorTab.VisitorLeadingZipcode ? visitorTab.LeadingZipCodeImpression : 'N/A'}</strong> visits.</div>)} */}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardChart;
